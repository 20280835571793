<!--
The NuWideEvent component displays an event with an image, title, subtitle, info, and actions. It receives the following props:
- event: an object with the following properties:
  - image: a string representing the image URL.
  - title: a string representing the event title.
  - subtitles: an array of strings representing the event subtitles.
  - infos: an array of strings representing the event information.
  - actions: an array of strings representing the event actions or object of actions with text and callback.
- infoPosition: a string representing the position of the info section. It can be 'top' or 'bottom'. The default value is 'bottom'.

Example usage:
<nu-wide-event :event="{ image: 'https://example.com/image.jpg', title: 'Event Title', subtitles: ['Subtitle 1', 'Subtitle 2'], info: ['Info 1', 'Info 2'], actions: ['Action 1', 'Action 2'] }" info-position="top" />

-->
<script setup>
const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  infoPosition: {
    type: String,
    default: 'bottom',
  },
  userSelect: {
    type: Boolean,
    default: false,
  },
  hideDetails: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['action'])

const eventTransformed = computed(() => {
  const transformActions = () => {
    if (!props.event.actions) {
      return []
    }

    if (Array.isArray(props.event.actions)) {
      return props.event.actions.map((action) => {
        return {
          text: action,
          action: () => {
            emit('action', action, props.event)
          },
        }
      })
    }

    return Object.entries(props.event.actions).map(([key, value]) => {
      return {
        text: value.text,
        action: () => {
          value.action(props.event)
          emit('action', key, props.event)
        },
      }
    })
  }

  return {
    ...props.event,
    actions: transformActions(),
  }
})

const hasActions = computed(() => {
  return eventTransformed.value.actions.length > 0
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-wide-event.css';
</style>

<template>
  <div
    :class="{'user-select': userSelect}"
    class="nu-wide-event"
  >
    <div class="left">
      <img :src="eventTransformed.image" />
    </div>
    <div
      :class="{'has-actions': hasActions}"
      class="right"
      v-show="!hideDetails"
    >
      <div
        class="infos top"
        v-if="infoPosition === 'top'"
      >
        <!-- TODO: Re write in render function for de duplication -->
        <div
          class="info"
          :key="`info-${index}`"
          v-for="(info, index) in eventTransformed.infos"
        >
          {{ info }}
        </div>
      </div>
      <div class="title">{{ eventTransformed.title }}</div>
      <div
        class="subtitle"
        :key="`subtitle-${index}`"
        v-for="(subtitle, index) in eventTransformed.subtitles"
      >
        {{ subtitle }}
      </div>
      <div
        class="infos bottom"
        v-if="infoPosition === 'bottom'"
      >
        <!-- TODO: Re write in render function for de duplication -->
        <div
          class="info"
          :key="`info-${index}`"
          v-for="(info, index) in eventTransformed.infos"
        >
          {{ info }}
        </div>
      </div>

      <div class="actions">
        <div
          class="action"
          :key="`action-${index}`"
          v-for="(action, index) in eventTransformed.actions"
          @click.prevent.stop="action.action"
        >
          <!-- slot -->
          {{ action.text }}
        </div>
      </div>
    </div>
  </div>
</template>
